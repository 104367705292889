import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
    color: #fff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #06060e;
  padding: 20px;
`;

const AuthForm = styled.div`
  background-color: #111;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
`;

const FormTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #ffba3b;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: lightgray;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: orange;
  }
`;

const AuthButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: orange;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffba3b;
  }
`;

const SwitchModeLink = styled.span`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #ffba3b;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const CheckboxLabel = styled.label`
  color: lightgray;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CheckboxInput = styled.input`
  margin-right: 10px;
`;

const LoginPage = () => {
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [newsletter, setNewsletter] = useState(true);
  const [understand, setUnderstand] = useState(false);

  const handleLogin = async () => {
    axios.post('/api/v1/login', {
      username: username,
      password: password,
    })
    .then((response) => {
      const { Token } = response.data;
      Cookies.set('token', Token);
      Cookies.set('username', username);
      window.location.replace('/');
    })
    .catch((error) => {
      alert(error);
    });
  };

  const handleRegister = async () => {
    if (!termsAccepted) {
      setError('You must accept the Terms of Service and Privacy Policy.');
      return;
    }
    
    const dataToSend = {
      username: username,
      password: password,
      email: email,
      newsletter: newsletter,
    };

    axios.post('/api/v1/register', dataToSend)
      .then((response) => {
        window.location.replace('/');
      })
      .catch((error) => {
        alert(error);
      });
  };

  const BlueskySignin = async () => {
    const clientId = 'https://yipyap.io/oauth/client-metadata.json';
    const redirectUri = 'https://yipyap.io/callback';
    // also update in client-metadata
    const scope = 'atproto transition:generic app.bsky.feed.read';
    const authorizationEndpoint = 'https://bsky.social/oauth/authorize';
  
    const codeVerifier = generateRandomString(128);
    const codeChallenge = await generateCodeChallenge(codeVerifier);
  
    await fetch('/api/v1/bsky/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ codeVerifier }),
      credentials: 'include',
    });
  
    const authUrl = `${authorizationEndpoint}?response_type=code&client_id=${encodeURIComponent(
      clientId
    )}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(
      scope
    )}&code_challenge=${encodeURIComponent(
      codeChallenge
    )}&code_challenge_method=S256`;
  
    window.location.href = authUrl;
  };

  const generateRandomString = (length) => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let result = '';
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    for (let i = 0; i < length; i++) {
      result += charset.charAt(array[i] % charset.length);
    }
    return result;
  };
  
  const base64UrlEncode = (buffer) => {
    return btoa(String.fromCharCode(...new Uint8Array(buffer)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  };
  
  const generateCodeChallenge = async (verifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier);
    const digest = await crypto.subtle.digest('SHA-256', data);
    return base64UrlEncode(digest);
  };


  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        <AuthForm>
          <FormTitle>{isLoginMode ? 'Login' : 'Create Account'}</FormTitle>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {!isLoginMode && (
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </FormGroup>
          {!isLoginMode && (
            <>
            <CheckboxLabel>
              <CheckboxInput
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
              I agree I have read the <a href="/terms" style={{ color: 'orange', textDecoration: 'none' }}>Terms of Service</a> and <a href="/privacy" style={{ color: 'orange', textDecoration: 'none' }}>Privacy Policy</a>
            </CheckboxLabel>
              <CheckboxLabel>
                <CheckboxInput
                  type="checkbox"
                  checked={understand}
                  onChange={() => setUnderstand(!understand)}
                />
                I understand that YipYap is currently in a <i>very</i> early beta state, and issues are to be expected.
              </CheckboxLabel>
              <CheckboxLabel>
                <CheckboxInput
                  type="checkbox"
                  checked={newsletter}
                  onChange={() => setNewsletter(!newsletter)}
                />
                I would like to receive occasional emails from YipYap (we will not email you more than once a month to once a week, and you can unsubscribe at any time with a single click)
              </CheckboxLabel>
            </>
          )}
          {isLoginMode && (
            <SwitchModeLink onClick={() => window.location.replace('/recovery')}>Forgot password?</SwitchModeLink>
          )}
          <AuthButton onClick={isLoginMode ? handleLogin : handleRegister}>
            {isLoginMode ? 'Login' : 'Create Account'}
          </AuthButton>
          <SwitchModeLink onClick={() => setIsLoginMode(!isLoginMode)}>
            {isLoginMode ? "Don't have an account? Create one" : 'Already have an account? Login'}
          </SwitchModeLink>
        </AuthForm>
        
        <button onClick={BlueskySignin}>Bluesky Test</button>
      </PageContainer>
    </React.Fragment>
  );
};

export default LoginPage;
